<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\bloggertrips\\edit.1886') }}</v-toolbar-title>
            </v-toolbar>
            <v-row class="my-0">
                <v-col>
                    <b>{{ $t('src\\views\\bloggertrips\\edit.878') }}</b>: {{ entity.blogger ? `${entity.blogger.social} (${entity.blogger.firstname} ${entity.blogger.lastname})` : '-' }}<br>
                    <b>{{ $t('src\\views\\bloggertrips\\edit.5670') }}</b>: {{ entity.organizer ? `${entity.organizer.firstname} ${entity.organizer.lastname}` : '-' }}
                </v-col>
                <v-col align="right">
                    <v-btn
                        :disabled="entity.status !== 'active'"
                        color="primary"
                        @click="sendEmail">{{ $t('src\\views\\bloggertrips\\edit.sendEmail') }}</v-btn>
                    &nbsp;&nbsp;&nbsp;
                    <v-btn
                        :disabled="entity.status !== 'active'"
                        color="primary"
                        @click="sendSms">{{ $t('src\\views\\bloggertrips\\edit.sendSms') }}</v-btn>
                </v-col>
            </v-row>
            <v-select
                v-model="entity.trip"
                :label="$t('src\\views\\bloggertrips\\edit.4265')"
                :items="trips"
                item-text="name"
                item-value="_id"
                outlined
            />
            <v-text-field
                v-model="entity.start"
                :label="$t('src\\views\\bloggertrips\\edit.start')"
                outlined
            />
            <v-text-field
                v-model="entity.finish"
                :label="$t('src\\views\\bloggertrips\\edit.finish')"
                outlined
            />
            <v-text-field
                v-model="entity.price"
                :label="`${$t('src\\views\\bloggertrips\\edit.price')} (${ currency })`"
                outlined
            />
            <v-text-field
                v-model="entity.price"
                :label="`${$t('src\\views\\bloggertrips\\edit.price')} (${ currency })`"
                outlined
            />
            <v-text-field
                v-model="entity.bloggerAward"
                :label="`${$t('src\\views\\bloggertrips\\edit.bloggerAward')} (${ currency })`"
                outlined
            />
            <v-text-field
                v-model="entity.bloggerPlaces"
                :label="$t('src\\views\\bloggertrips\\edit.5465')"
                outlined
            />

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\bloggertrips\\edit.5562') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\bloggertrips\\edit.690') }}</v-btn>
            </v-row>
        </v-form>
        <v-alert   
            v-model="sendSuccessAlert" 
            style="position: fixed; top: 10px; right: 10px; z-index: 100;"
            dismissible
            prominent
            type="success"
        >{{ $t('src\\views\\bloggertrips\\edit.sendSuccess') }}</v-alert>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, translit } from '../../helpers';
    import { DIRECTION_TYPE, DIRECTION_TYPE_TITLE } from '../../vars';

    export default {
        name: 'BloggerTripsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        data: () => ({
            DIRECTION_TYPE,
            DIRECTION_TYPE_TITLE,
            imageSrc,
            valid: true,
            sendSuccessAlert: false
        }),
        computed: {
            ...mapState('bloggerTrips', ['entity']),
            ...mapState('trips', {
                trips: state => state.entities
            }),
            tripsSelect() {
                return [
                    { _id: null, name: 'По умолчанию' },
                    ...this.trips
                ]
            },
            directionTypesSelect() {
                const directionTypes = [];
                for(const item in DIRECTION_TYPE) {
                    directionTypes.push({
                        value: DIRECTION_TYPE[item],
                        title: DIRECTION_TYPE_TITLE[DIRECTION_TYPE[item]]
                    })
                }
                return directionTypes;
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY
            }
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('bloggerTrips/get', { id: this.$route.params.id });
            } else {
                await store.commit('bloggerTrips/CLEAR_ENTITY');
            }
            await store.dispatch('trips/fetch', { filter: { user: this.entity.organizer.id }, itemsPerPage: 100000 });
        },
        methods: {
            async sendEmail() {
                await store.dispatch('bloggerTrips/sendEmail', { id: this.entity._id });
                this.sendSuccessAlert = true;
            },
            async sendSms() {
                await store.dispatch('bloggerTrips/sendSms', { id: this.entity._id });
                this.sendSuccessAlert = true;
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('bloggerTrips/SET_ENTITY', this.entity);
                    const response = await store.dispatch('bloggerTrips/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'blogger-trips-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('bloggerTrips/delete', { id: this.entity._id });
                await this.$router.push({ name: 'blogger-trips-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .bloggerTrips__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
